<template>
  <div>
    <b-loading :is-full-page="true" :active="fullScreenLoaderActive" />
    <full-screen-loader
      v-if="waitingForInitialZorgplatformPatientData"
      :text="$t('patient_detail_page.retrieving_data_from_hix')"
    />
    <!-- Patient actions (new screening / refresh). These are hidden when the patient is locked. -->
    <Portal to="subheader-actions" key="patient-actions" v-if="!isLocked.value">
      <span class="mb-2 mr-2 is-hidden-mobile" v-if="!singlePatientMode">
        {{
          $t("common.last_refreshed_on", { updatedOn: formatTime(updatedOn) })
        }}
      </span>

      <!-- Overall data refresh button; hidden in single patient mode. -->
      <b-button
        v-if="!singlePatientMode"
        type="is-secondary"
        @click="onRefreshClick"
        icon-left="sync-alt"
        :loading="isRefreshingPatient"
        >{{ $t("common.retrieve_new_data") }}
      </b-button>

      <!-- Patient actions, the backend determines what can be done here. -->
      <div class="is-flex is-flex-direction-row-reverse patient-action-buttons">
        <template v-for="action in patient.actions">
          <b-button
            :type="action.is_primary ? 'is-primary' : 'is-secondary'"
            :key="action.key"
            :icon-left="action.icon"
            @click="onActionButtonClick(action)"
            >{{ action.label }}
          </b-button>
        </template>
      </div>

      <b-modal
        v-model="isFormModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        :can-cancel="false"
      >
        <template #default>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ activeAction.confirm.title }}</p>
            </header>
            <section class="modal-card-body">
              <p class="mb-4">
                {{ activeAction.confirm.content }}
              </p>
              <Form
                :fields="fields"
                :onSubmitClick="performAction"
                :submitLabel="activeAction.confirm.confirm_button_text"
                v-model="formData"
              >
                <template v-slot:form-actions>
                  <b-button @click="isFormModalActive = false"
                    >{{ activeAction.confirm.cancel_button_text }}
                  </b-button>
                </template>
              </Form>
            </section>
          </div>
        </template>
      </b-modal>
    </Portal>
    <transition name="fade">
      <div class="columns" v-if="!isLoadingPatient">
        <div class="column is-half-desktop vertical-card-spacing">
          <Card :title="$t('common.patient') | titleize" icon="user">
            <template
              v-if="
                patient.has_workflow &&
                (!patient.form_values.phone || !patient.form_values.email)
              "
            >
              <b-message type="is-warning" has-icon>
                <div class="is-flex is-flex-direction-row">
                  <span class="is-align-self-center">
                    {{
                      $t(
                        "patient_detail_page.important_patient_info_missing_message"
                      )
                    }}
                  </span>
                </div>
              </b-message>
            </template>

            <patient-info :patient="patient" />

            <template v-slot:card-footer v-if="!isLocked.value">
              <div class="buttons">
                <b-button
                  v-if="!patient.has_workflow"
                  type="is-primary"
                  @click="$router.push(`/patient/${patientId}/edit`)"
                  >{{ $t("common.change_data") }}
                </b-button>

                <template v-if="patient.has_workflow">
                  <span class="is-flex" v-if="isRefreshingPatient">
                    {{ $t("patient_detail_page.refreshing_data_from_hix_now") }}
                    <span class="dots"></span>
                  </span>
                  <span v-else
                    >{{
                      $t("common.last_refreshed_on", {
                        updatedOn: formatTime(updatedOn.toString()),
                      })
                    }}
                  </span>
                  <b-button
                    type="is-secondary"
                    @click="doRefreshEpdPatientClick"
                    icon-left="sync-alt"
                    :loading="isRefreshingPatient"
                    >{{ $t("common.refresh_data") }}
                  </b-button>
                </template>
              </div>
            </template>
          </Card>
          <tasks
            :patient="patient"
            v-if="patient.task_types && patient.task_types.length > 0"
          />

          <Card :title="$t('common.notes') | titleize" icon="notes-medical">
            <b-input
              type="textarea"
              :placeholder="
                isLocked.value ? '' : $t('patient_detail_page.place_note_here')
              "
              rows="3"
              v-model="patient.notes"
              custom-class="full-textarea"
              v-resize-textarea
              :readonly="isLocked.value"
            ></b-input>
            <template v-slot:card-footer v-if="!isLocked.value">
              <b-button
                type="is-primary"
                @click="onNotesSaveClick"
                :loading="isSavingNotes"
                :disabled="!isDirtyNotes"
              >
                {{ $t("common.save") }}
              </b-button>
            </template>
          </Card>
          <Card
            :title="$t('common.history') | titleize"
            icon="history"
            class="is-hidden-mobile"
          >
            <history :history="patient.logs" />
          </Card>
        </div>
        <div class="column is-half vertical-card-spacing">
          <card
            :dashed="true"
            v-if="patient.screenings.length === 0 && !singlePatientMode"
            class="is-italic"
          >
            {{ $t("patient_detail_page.no_screenings_yet_message") }}
          </card>
          <screening-card
            v-for="screening in patient.screenings"
            :screening="screening"
            :key="screening.id"
            :updateScreening="updateScreening"
            v-else
          >
          </screening-card>
          <Card
            :title="$t('common.history') | titleize"
            icon="history"
            class="is-hidden-tablet"
          >
            <history :history="patient.logs" />
          </Card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import axios from "axios";

import Card from "@/components/Card.vue";

import {
  getPatient,
  updatePatientNotes,
  refreshPatientDetails,
  postPatientAction,
  getPatientActionForm,
  getPatientActionValues,
} from "@/api";
import { FormField } from "@/types/form";
import { Patient } from "@/types/patient";
import PatientInfo from "@/components/PatientInfo.vue";
import Tasks from "@/components/Tasks.vue";
import History from "@/components/History.vue";
import eventBus from "@/utils/eventBus";

import ScreeningCard from "@/components/ScreeningCard.vue";
import { cloneDeep, isEqual, keyBy, mapValues } from "lodash";
import { Route } from "vue-router";
import { NavigationGuardNext } from "vue-router/types/router";
import { Action, Screening } from "@/types/screening";
import Form from "@/components/Form/Form.vue";
import FullScreenLoader from "@/components/Common/FullScreenLoader.vue";

import { Cache } from "@/utils/cache";
import { longerThanNSecondsAgo } from "@/utils/functions";
import { AxiosRequestConfig } from "axios";
import { formatTime } from "@/filters/date";
import i18n from "@/i18n";

export default Vue.extend({
  name: "PatientDetails",
  inject: ["isLocked", "authMode", "singlePatientMode"],
  components: {
    FullScreenLoader,
    Card,
    PatientInfo,
    History,
    ScreeningCard,
    Tasks,
    Form,
  },

  // This appears to be a hack to get a type for authMode
  props: {
    authMode: String,
  },

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },

  data() {
    return {
      patient: {} as Patient,
      cachedPatient: {} as Patient,
      isLoadingPatient: true,
      isCreatingScreening: false,
      isSavingNotes: false,
      isRefreshingPatient: false,
      updatedOn: null as Date | null,
      isFormModalActive: false,
      fields: [] as FormField[],
      formData: {},
      activeAction: {} as Action,
      abortController: null as AbortController | null,
      formatTime: formatTime,
    };
  },
  computed: {
    isDirtyNotes(): boolean {
      return (
        !!this.cachedPatient &&
        !isEqual(this.cachedPatient.notes, this.patient.notes)
      );
    },
    changed(): boolean {
      return this.isDirtyNotes;
    },
    patientId(): number {
      return parseInt(this.$route.params?.patientId);
    },
    fullScreenLoaderActive(): boolean {
      return (
        this.isLoadingPatient && !this.waitingForInitialZorgplatformPatientData
      );
    },
    waitingForInitialZorgplatformPatientData(): boolean {
      if (!this.patient && this.authMode === "server") {
        return true;
      }

      if (!this.isRefreshingPatient) {
        return false;
      }

      return this.patient.has_workflow && !this.patient.active_screening_loaded;
    },
  },
  methods: {
    guard: function (next: NavigationGuardNext): void {
      if (this.changed) {
        this.$buefy.dialog.confirm({
          title: i18n
            .t("patient_detail_page.dialog_open_changes.title")
            .toString(),
          message: i18n
            .t("patient_detail_page.dialog_open_changes.message")
            .toString(),
          cancelText: i18n
            .t("patient_detail_page.dialog_open_changes.cancel")
            .toString(),
          confirmText: i18n
            .t("patient_detail_page.dialog_open_changes.confirm")
            .toString(),
          type: "is-danger",
          onConfirm: () => {
            next();
          },
          onCancel: () => {
            next(false);
          },
        });
      } else {
        next();
      }
    },
    onRefreshClick(showToast = true) {
      if (this.patient.has_workflow) {
        this.doRefreshEpdPatientClick(showToast);
      } else {
        this.doRefreshPatient();
      }
    },
    async doRefreshPatient() {
      try {
        this.isRefreshingPatient = true;
        const { data } = await getPatient(this.patientId);
        this.patient = { ...data };
        this.$buefy.toast.open({
          message: i18n.t("common.refresh_data_done").toString(),
          type: "is-success",
        });

        this.isRefreshingPatient = false;
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n.t("common.refresh_data_error_message").toString(),
          type: "is-danger",
        });
        this.isRefreshingPatient = false;
      }
    },
    async doRefreshEpdPatientClick(showToast = true) {
      try {
        this.isRefreshingPatient = true;
        this.abortController = new AbortController();

        const { data } = await refreshPatientDetails(this.patientId, {
          signal: this.abortController.signal,
        } as AxiosRequestConfig);

        this.patient = { ...data };

        if (showToast) {
          this.$buefy.toast.open({
            message: i18n.t("common.refresh_data_done").toString(),
            type: "is-success",
          });
        }
        this.isRefreshingPatient = false;
        this.updatedOn = new Date();

        Cache.set(
          this.patient.id.toString() + "_called_refresh_at",
          this.updatedOn
        );
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        if (showToast) {
          this.$buefy.toast.open({
            message:
              (err as any).customErrorMessage ||
              i18n.t("common.refresh_data_error_message").toString(),
            type: "is-danger",
          });
        }
        this.isRefreshingPatient = false;
      }
    },
    updatePatient(patientData: Patient) {
      this.patient = { ...patientData };
      this.isLoadingPatient = false;
      this.updatedOn = new Date();
      this.cachedPatient = cloneDeep(this.patient);
    },
    async getPatient(patientId: number): Promise<Patient> {
      const { data } = await getPatient(patientId);

      this.updatePatient(data);

      return data;
    },
    updateScreening(screeningId: number, screening: Screening) {
      const screeningIndex = this.patient.screenings.findIndex(
        (s) => s.id === screeningId
      );

      if (screening.id) {
        // Screening was updated
        this.patient.screenings.splice(screeningIndex, 1, screening);
      } else {
        // Screening was deleted
        this.patient.screenings.splice(screeningIndex, 1);
      }
    },
    async onNotesSaveClick() {
      try {
        this.isSavingNotes = true;
        const { data: patient } = await updatePatientNotes(
          this.patientId!,
          this.patient.notes
        );

        this.patient = { ...patient };
        this.cachedPatient = cloneDeep(this.patient);

        this.$buefy.toast.open({
          message: i18n.t("patient_detail_page.notes_saved").toString(),
          type: "is-success",
        });
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n.t("common.refresh_data_error_message").toString(),
          type: "is-danger",
        });
      } finally {
        this.isSavingNotes = false;
      }
    },

    async performAction() {
      try {
        const { data } = await postPatientAction(
          this.patient.id,
          this.activeAction.key,
          this.formData
        );

        this.patient = data;
        this.$buefy.toast.open({
          message: i18n.t("common.perform_action_done").toString(),
          type: "is-success",
        });
        this.isFormModalActive = false;
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n.t("common.perform_action_error_message").toString(),
          type: "is-danger",
        });
      }
    },

    async onActionButtonClick(action: Action) {
      this.activeAction = action;

      if (!action.confirm) {
        // No buttons, direct action
        await this.performAction();
        return;
      }

      // Action with a confirm dialog or a form
      const { data } = await getPatientActionForm(this.patient.id, action.key);

      if (data?.length > 0) {
        // Show a form modal
        // WARNING: This currently only returns ['id' => $patient->id]
        // from the API, which means other fields aren't reactive unless
        // we make them reactive.
        const { data: formValues } = await getPatientActionValues(
          this.patient.id,
          action.key
        );

        // Make sure all properties in the form are present in formData
        const base = mapValues(
          keyBy(data, (field) => field.key),
          () => null
        );
        this.formData = {
          ...base,
          formValues,
        };
        this.fields = data;

        this.isFormModalActive = true;
        return;
      }

      // Show just a confirm dialog
      this.$buefy.dialog.confirm({
        hasIcon: !!action.confirm.icon,
        icon: action.confirm.icon,
        title: action.confirm.title,
        message: action.confirm.content,
        cancelText: action.confirm.cancel_button_text,
        confirmText: action.confirm.confirm_button_text,
        type: "is-info",
        onConfirm: () => this.performAction(),
      });
    },
  },
  async mounted() {
    eventBus.$on("update-patient", (patientData: Patient) => {
      if (patientData) {
        this.updatePatient(patientData);
      } else {
        this.getPatient(this.patientId);
      }
    });

    eventBus.$on("update-patient-from-lock", (patientData: Patient) => {
      this.patient = {
        ...this.patient,
        logs: patientData.logs,
        screenings: patientData.screenings,
      };
    });

    if (this.patientId) {
      const patient = await this.getPatient(this.patientId);

      if (patient.has_workflow) {
        const lastRefreshedAt = Cache.get(
          this.patientId.toString() + "_called_refresh_at"
        );

        if (lastRefreshedAt && !longerThanNSecondsAgo(lastRefreshedAt, 120)) {
          return;
        }

        this.onRefreshClick(false);
      }
    }
  },
  beforeDestroy() {
    eventBus.$off("update-patient");
  },
});
</script>

<style lang="scss" scoped>
:deep(.full-textarea) {
  border: none !important;
  box-shadow: none !important;
  padding: 0 0 0 8px;
  margin-left: -0.5em;
  resize: none;
  outline: none;

  &:active {
    border: none;
    box-shadow: none;
  }
}

.patient-action-buttons {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1100px) {
    & {
      margin-bottom: -0.5em;
    }
    .button {
      margin-bottom: 0.5em;
    }
  }
}

.patient-action-buttons .button {
  margin-left: 0.5em;
}

.buttons > {
  * + * {
    // all siblings get a small left margin.
    margin-left: 0.5rem;
  }
}

.subtitle {
  font-size: 1rem;
  font-weight: normal;
  float: right;
  position: relative;
  right: 0;
}
</style>
