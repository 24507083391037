import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import { getUserSession } from "@/api";
import {
  AuthUser,
  currentUser,
  getParsedJwt,
  handleAuthToken,
  SessionUser,
  hasAuthToken,
  userScopedToPatient,
} from "@/utils/authUtil";
import i18n, { guessActiveLocale } from "@/i18n";
import { AxiosError } from "axios";
import SelectTenant from "@/views/SelectTenant.vue";
import { getAppAuthMethod, serverModeDebugKey } from "@/api/auth_method";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  //#region Video calling
  {
    path: "/call/h/:callId/:key",
    name: "call-host",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/VideoCall.vue"),
    meta: {
      public: true,
      hideHeader: true,
    },
  },
  {
    path: "/call/p/:callId/:key",
    name: "call-patient",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/VideoCall.vue"),
    meta: {
      public: true,
      hideHeader: true,
    },
  },
  //#endregion

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "routes.title.login",
      public: true,
    },
  },

  {
    path: "/patient-login/:handle?",
    name: "patientCreateGateway",
    component: () =>
      import(
        /* webpackChunkName: "patients" */ "@/views/PatientLoginGateway.vue"
      ),
    meta: {
      public: true,
      hideHeader: true,
      restoreAfterAuth: true,
    },
  },

  {
    path: "/select-tenant",
    name: "select-tenant",
    component: SelectTenant,
    meta: {
      title: "routes.title.select_tenant",
      public: true,
    },
  },

  {
    path: "/sso/open-id/:handle",
    name: "openId",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/OpenIdConnect.vue"),
    meta: {
      title: "routes.title.open_id",
      public: true,
    },
  },
  // Having the handle in the callback route is deprecated, I want to avoid having to
  // configure each specific handle in each OpenID provider. This route is
  // present for backward compatibility with HMC Workspace One, which has
  // a redirect URI containing the handle. Note that the initialization route
  // still clearly needs a handle, as we need to know who to initialize login for.
  {
    path: "/sso/open-id-login/:handle?",
    name: "openIdLogin",
    component: () =>
      import(
        /* webpackChunkName: "patients" */ "@/views/OpenIdConnectLogin.vue"
      ),
    meta: {
      title: "routes.title.open_id_login",
      public: true,
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/Forbidden.vue"),
    meta: {
      title: "routes.title.forbidden",
      public: true,
    },
  },
  {
    path: "/no-session",
    name: "no-session",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/NoSession.vue"),
    meta: {
      title: "routes.title.no_session",
      public: true,
    },
  },
  {
    path: "/password",
    name: "password",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/PasswordReset.vue"),
    meta: {
      title: "routes.title.password",
      public: true,
    },
  },
  {
    path: "/clinic/:ssoHandle?",
    name: "clinic-gateway",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/ClinicGateway.vue"),
    meta: {
      hideHeader: true,
      restoreAfterAuth: true,
    },
  },
  {
    path: "/patients",
    name: "patient-overview",
    component: () =>
      import(/* webpackChunkName: "patients" */ "@/views/Patients.vue"),
    meta: {
      title: "routes.title.patient_overview",
      blockedAuthMethods: ["server"],
    },
  },

  {
    path: "/users",
    name: "user-overview",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/Users.vue"),
    meta: {
      title: "routes.title.user_overview",
      blockedAuthMethods: ["server"],
    },
  },
  {
    path: "/user/:userId(\\d+)",
    name: "user-details",
    component: () =>
      import(/* webpackChunkName: "patient-details" */ "@/views/UserEdit.vue"),
    meta: {
      title: "routes.title.user_details",
      breadcrumb: { parent: "/users" },
      blockedAuthMethods: ["server"],
    },
  },

  {
    path: "/user/create",
    name: "user-create",
    component: () =>
      import(/* webpackChunkName: "patient-details" */ "@/views/UserEdit.vue"),
    meta: {
      title: "routes.title.user_edit",
      breadcrumb: { parent: "/users" },
      blockedAuthMethods: ["server"],
    },
  },

  {
    path: "/operations",
    name: "operations-overview",
    component: () =>
      import(/* webpackChunkName: "operations" */ "@/views/Operations.vue"),
    meta: {
      title: "routes.title.operation_overview",
      blockedAuthMethods: ["server"],
    },
  },
  {
    path: "/operation/:operationId(\\d+)",
    name: "operation-details",
    component: () =>
      import(
        /* webpackChunkName: "operation-details" */ "@/views/OperationEdit.vue"
      ),
    meta: {
      title: "routes.title.operation_details",
      breadcrumb: { parent: "/operations" },
      blockedAuthMethods: ["server"],
    },
  },

  {
    path: "/operation/create",
    name: "operation-create",
    component: () =>
      import(
        /* webpackChunkName: "operation-details" */ "@/views/OperationEdit.vue"
      ),
    meta: {
      title: "routes.title.operation_create",
      breadcrumb: { parent: "/operations" },
      blockedAuthMethods: ["server"],
    },
  },

  {
    path: "/patient",
    name: "patient-router",
    component: () =>
      import(
        /* webpackChunkName: "patient-router" */ "@/views/PatientRouter/PatientRouter.vue"
      ),
    meta: {
      title: "routes.title.patient_overview",
    },

    children: [
      {
        path: ":patientId(\\d+)",
        name: "patient-details",
        component: () =>
          import(
            /* webpackChunkName: "patient-details" */ "@/views/PatientRouter/Patient.vue"
          ),
        meta: {
          title: "routes.title.patient_details",
          breadcrumb: { parent: "/patients" },
        },
      },
      {
        path: "create",
        name: "patient-create",
        component: () =>
          import(
            /* webpackChunkName: "patient-create" */ "@/views/PatientRouter/PatientEdit.vue"
          ),
        meta: {
          title: "routes.title.patient_create",
          breadcrumb: { parent: "/patients" },
          blockedAuthMethods: ["server"],
        },
      },
      {
        path: "/patient/:patientId(\\d+)/edit",
        name: "patient-edit",
        component: () =>
          import(
            /* webpackChunkName: "patient-edit" */ "@/views/PatientRouter/PatientEdit.vue"
          ),
        meta: {
          title: "routes.title.patient_edit",
          breadcrumb: { parent: "/patient/:patientId" },
          blockedAuthMethods: ["server"],
        },
      },
      {
        path: "/patient/:patientId(\\d+)/screening/:screeningId(\\d+)/prepare",
        name: "screening-prepare",
        component: () =>
          import(
            /* webpackChunkName: "prepare" */ "../views/PatientRouter/Prepare.vue"
          ),
        meta: {
          title: "routes.title.screening_prepare",
          breadcrumb: { parent: "/patient/:patientId" },
        },
      },
      {
        path: "/patient/:patientId(\\d+)/screening/:screeningId(\\d+)/process",
        name: "screening-process",
        component: () =>
          import(
            /* webpackChunkName: "process" */ "@/views/PatientRouter/Report.vue"
          ),
        meta: {
          title: "routes.title.screening_process",
          breadcrumb: { parent: "/patient/:patientId" },
        },
      },
      {
        path: "/patient/:patientId(\\d+)/screening/:screeningId(\\d+)/process-questionnaire",
        name: "screening-process-questionnaire",
        component: () =>
          import(
            /* webpackChunkName: "process" */ "@/views/PatientRouter/Questionnaire.vue"
          ),
        meta: {
          title: "routes.title.screening_process_questionnaire",
          breadcrumb: { parent: "/patient/:patientId" },
        },
      },
      {
        path: "/patient/:patientId(\\d+)/screening/:screeningId(\\d+)/report",
        name: "screening-report",
        component: () =>
          import(
            /* webpackChunkName: "report" */ "@/views/PatientRouter/Report.vue"
          ),
        meta: {
          title: "routes.title.screening_report",
          breadcrumb: { parent: "/patient/:patientId" },
          handlesPadding: true,
        },
      },

      // TODO The questionnaire is present inline in the report tabs, this might be dead code.
      {
        path: "/patient/:patientId(\\d+)/screening/:screeningId(\\d+)/questionnaire",
        name: "screening-questionnaire",
        component: () =>
          import(
            /* webpackChunkName: "report" */ "@/views/PatientRouter/Questionnaire.vue"
          ),
        meta: {
          title: "routes.title.screening_questionnaire",
          breadcrumb: { parent: "/patient/:patientId" },
        },
      },
      {
        path: "/me",
        name: "me",
        component: () =>
          import(/* webpackChunkName: "user-profile" */ "@/views/Profile.vue"),
        meta: {
          title: "routes.title.user_edit",
          breadcrumb: { parent: "/" },
          blockedAuthMethods: ["server"],
        },
      },
      {
        path: "/exports",
        name: "exports",
        component: () =>
          import(
            /* webpackChunkName: "exports" */ "@/views/ExportRouter/Exports.vue"
          ),
        meta: {
          title: "routes.title.exports",
          blockedAuthMethods: ["server"],
        },
      },
      {
        path: "/export",
        name: "export-router",
        component: () =>
          import(
            /* webpackChunkName: "export-router" */ "@/views/ExportRouter/ExportRouter.vue"
          ),
        meta: {
          title: "routes.title.exports",
          blockedAuthMethods: ["server"],
        },
        children: [
          {
            path: ":exportId(\\d+)",
            name: "export-details",
            component: () =>
              import(
                /* webpackChunkName: "exports" */ "@/views/ExportRouter/ExportView.vue"
              ),
            meta: {
              title: "routes.title.exports_details",
              breadcrumb: { parent: "/exports" },
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Session storage key indicating which page redirected to the login flow.
export const authFromKey = "authRequiredFrom";

// Sets the new value of the URL that required login in session storage,
// and returns the value that was stored before the overwrite.
export function setAuthRequiredFrom(url?: string | null) {
  const current = sessionStorage.getItem(authFromKey);

  if (url) {
    sessionStorage.setItem(authFromKey, url);
  } else {
    sessionStorage.removeItem(authFromKey);
  }

  return current;
}

/**
 * Determine the route of a user based based on its rights. This is only valid is the
 * path that was requested is equal to '/'.
 *
 * @param user
 */
export const determineLandingRoute = (user: AuthUser | SessionUser | null) => {
  const authFrom = setAuthRequiredFrom(null);

  if (!user) {
    return false;
  }

  // If the user has a patient scope, return the patient route
  if (userScopedToPatient(user)) {
    return router.resolve({
      name: "patient-details",
      params: { patientId: user.patient_id!.toString() },
    }).href;
  }

  if (!("permissions" in user)) {
    return "/forbidden";
  }

  if (authFrom) {
    const routeFrom = router.resolve(authFrom);

    if (authFrom?.startsWith("/clinic")) {
      // If our target was the clinic login page, move there directly.
      // Add a session storage entry indicating that we logged in
      // specifically to get into the clinic page.
      sessionStorage.setItem("login_for_clinic", "1");
    }

    if (routeFrom.route.meta?.restoreAfterAuth) {
      return authFrom;
    }
  }

  const permissions = user.permissions;

  if (permissions.includes("screening-view")) {
    return "/patients";
  } else if (permissions.includes("user-manager")) {
    return "/users";
  }
};

let hasServerSession = false;
export const initServerSession = async (): Promise<SessionUser> => {
  const { data: user } = await getUserSession();
  hasServerSession = true;
  return user;
};

router.beforeEach(async (to, from, next) => {
  // For more convenient debugging of server mode
  if (to?.query[serverModeDebugKey]) {
    sessionStorage.setItem(serverModeDebugKey, "1");
  }
  const authRequired = !to.meta?.public;

  const blocked = to?.meta?.blockedAuthMethods?.includes(getAppAuthMethod());

  if (!currentUser.value && sessionStorage.getItem("token")) {
    currentUser.value = getParsedJwt("token");
  }

  // console.log(currentUser.value);

  // Set the app language to that of the logged in user preferentially,
  // fall back to the browser language. VueI18n will automatically fall
  // back to any dialect available for the given locale (e.g. `en-US` will
  // automatically fall back to `en`), or finally to the configured
  // VueI18n.fallbackLocale.
  const locale =
    currentUser.value?.locale ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language;

  if (locale && i18n.locale !== locale) {
    i18n.locale = locale;
  }

  const activeLocale = guessActiveLocale(locale ?? i18n.fallbackLocale);

  // Set the estimated html lang attribute
  document.documentElement.lang = activeLocale;
  document.documentElement.dir = activeLocale === "ar" ? "rtl" : "ltr";

  if (!authRequired) {
    next();
    return;
  }

  if (hasAuthToken(to)) {
    handleAuthToken(to);
    next("/patient");
    return;
  }

  if (!currentUser.value) {
    setAuthRequiredFrom(to.fullPath);

    if (getAppAuthMethod() === "server" && !hasServerSession) {
      try {
        currentUser.value = await initServerSession();
        next("/patient/" + currentUser.value.patient_id);
      } catch (err) {
        if ((err as AxiosError).response?.status === 404) {
          next("/no-session");
        } else {
          next("/login");
        }
      }

      return;
    }

    if (to.name === "clinic-gateway" && to.params["ssoHandle"]) {
      // Redirect to the OpenID Connect login page
      next({
        name: "openId",
        params: { handle: to.params["ssoHandle"] },
      });
      return;
    }

    // Redirect to the login page
    next("/login");
    return;
  }

  if (blocked) {
    next("/forbidden");
    return;
  }

  if (to.path === "/") {
    next(determineLandingRoute(currentUser.value));
    return;
  }

  next();
});

export default router;
